import Vue from 'vue';
import axios from 'axios';
import vue_axios from 'vue-axios';
import app from '../main'

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use( config => {
    config.headers['Accept-Language'] = 'es_MX';
    app.$Progress.start();
    return config;
});

axios.interceptors.response.use( response => {
    app.$Progress.finish();
    return response;
}, error => {
    app.$Progress.fail();
    console.error("Error in axios request >>>", error);
    app.$toasted.error( String(app.$t(error.response.data)) ,
        {
            icon: 'warning',
            action: {
                text: 'Cerrar', onClick: (e, toastObject) => { toastObject.goAway(0)}
            }
        });
    return error;
});

Vue.prototype.$axiosCall = function (method, url, data, body, auth) {
    return Vue.axios({
        method: method,
        url: `api${url}`,
        data: data,
        headers: {'Authorization': auth? app.$store.state.security.logged_user.token : null}
    });
};

Vue.use(vue_axios, axios);
