import Vue from 'vue'
import App from './app/app.vue'
import router from './router'
import store from './store'
import VueCurrencyFilter from "vue-currency-filter";

Vue.use(VueCurrencyFilter, {
    symbol: '$',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false
});

Vue.use(require('vue-moment'));

require('./plugins');

Vue.config.productionTip = false;

export default new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
