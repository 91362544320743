import Home from "../views/home/home";

export default [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/security',
        name: 'security',
        component: () => import('../views/security/index/index.vue'),
        children: [
            {
                name: 'security.activate',
                path: 'account/activate/:token',
                component: () => import('../views/security/activate/activate.vue')
            },
            {
                name: 'security.register',
                path: 'register',
                component: () => import('../views/security/register/register.vue')
            },
            {
                name: 'security.login',
                path: 'login',
                component: () => import('../views/security/login/login.vue')
            },
            {
                name: 'security.forgot',
                path: 'account/recovery',
                component: () => import('../views/security/forgot/forgot.vue')
            },
            {
                name: 'security.reset',
                path: 'account/reset/:token',
                component: () => import('../views/security/reset/reset.vue')
            }
        ]
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('../views/user/index/index'),
        meta: {requiresAuth: true},
        children: [
            {
                path: 'sites',
                name: 'user.sites',
                component: () => import('../views/user/sites/sites.vue')
            },
            {
                path: 'account',
                name: 'user.account',
                component: () => import('../views/user/account/account.vue')
            },
            {
                path: 'site/:id',
                name: 'user.site',
                component: () => import('../views/user/site/site.vue')
            },
            {
                path: 'service/:id',
                name: 'user.service',
                component: () => import('../views/user/service/service.vue')
            },
            {
                path: 'leads/:id',
                name: 'user.leads',
                component: () => import('../views/user/leads/leads.vue')
            },
            {
                path: 'subscriptions/:id',
                name: 'user.subscriptions',
                component: () => import('../views/user/subscriptions/subscriptions.vue')
            }
        ]
    },
    {
        path: '/detail',
        name: 'detail',
        component: () => import('../views/user/index'),
        children: [
            {
                path: 'category/:id',
                name: 'detail.category',
                component: () => import('../views/detail/category')
            },
            {
                path: 'product/:id',
                name: 'detail.product',
                component: () => import('../views/detail/product')
            },
            {
                path: 'order/:id',
                name: 'detail.order',
                component: () => import('../views/detail/order')
            },
        ]
    },
    {
        path: '/create',
        name: 'create',
        component: () => import('../views/user/index/index'),
        children: [
            {
                path: 'adviser/:id',
                name: 'create.adviser',
                component: () => import('../views/form/adviser')
            }
        ]
    },
    {
        path: '/edit',
        name: 'edit',
        component: () => import('../views/user/index/index'),
        children: [
            {
                path: 'site/:id',
                name: 'edit.site',
                component: () => import('../views/form/site')
            },
            {
                path: 'adviser/:id',
                name: 'edit.adviser',
                component: () => import('../views/form/adviser')
            },
            {
                path: 'adviser/images/:id',
                name: 'edit.adviser.images',
                component: () => import('../views/form/images/images')
            }
        ]
    }
];
