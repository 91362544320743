import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import security from './security'
import common from './common'
import service from './service'

Vue.use(Vuex);

const localStorage = new VuexPersist({
  storage: window.localStorage,
  modules: ['security', 'common']
});

// const sessionStorage = new VuexPersist({
//   storage: window.sessionStorage,
//   modules: ['profile']
// });

export default new Vuex.Store({
  modules: {
    security,
    common,
    service
  },
  plugins: [localStorage.plugin]
});
