import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "./routes";
import store from '../store'


Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    const isAuth = store.state.security.logged_user;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if(!isAuth && requiresAuth) return next('/security/login');
    if(isAuth && to.name.includes('security')) return next(`/user/sites`);
    next();
});

export default router
