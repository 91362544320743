import app from '../../main'

export async function searchChild(context, {type, service, offset, limit, query, category, status}) {
    let url = `/${type}/list/${service}?offset=${offset || ''}&limit=${limit || ''}&query=${query || ''}&category=${category || ''}&status=${status || ''}`;
    let response = await app.$axiosCall('GET', url, null,null, true);
    return response.data;
}

export async  function createChild(context, {type, params}) {
    return await app.$axiosCall('POST', `/${type}/create`, params,null, true);
}

export async function update(context, params) {
    return await app.$axiosCall('PUT', `/${params.type}/update/${params.id}`, params.data,null, true);
}

export async function cancelOrder(context, id) {
    return await app.$axiosCall('PUT', `/order/cancel/${id}`, null ,null, true);
}

export async function setImage(context, {type, operation, id, url}) {
    return await app.$axiosCall('PUT', `/images/${type}/${operation}/${id}`, {url},null, true);
}
