import Vue from 'vue';
import app from '../../main'

export async function login(context, user) {
    let response = await app.$axiosCall('POST', '/security/login', user, null, false);
    if(response.status !== 200) return false;
    context.commit('set', response.data);
    await app.$router.push('/user/sites');
    return response.status
}

export async function register(context, user) {
    let response = await app.$axiosCall('POST', '/security/register', user, null, false);
    return response.status === 200 || response.status === 201
}

export async function forgotPassword(context, user) {
    let response = await app.$axiosCall('POST', '/security/account/recovery', user, null, false);
    return response.status === 200
}

export async function resetPassword(context, {token, user}) {
    let response = await app.$axiosCall('POST', `/security/account/reset/${token}`, user, null, false);
    return response.status === 200
}

export function updatePassword(context, user) {
    return Vue.axios({
        method: "PUT",
        url: `/security/password/update`,
        headers: {Authorization: context.getters.accessToken},
        data: user
    });
}

export async function validateToken(context, token) {
    let response = await app.$axiosCall('GET', `/security/token/validate/${token}`, null, null, false);
    return response.status === 200;
}

export async function activateAccount(context, token) {
    let response = await app.$axiosCall('PUT', `/security/account/activate/${token}`, null, null, false);
    return response.status === 200
}

export function sessionList(context) {
    return Vue.axios({
        method: "GET",
        url: "/security/session/list",
        headers: {authorization: context.state.access_token}
    })
}

export function deleteSession(context, id) {
    let url = id? `/security/session/delete/${id}` : "/security/session/delete";
    return Vue.axios({
        method: "DELETE",
        url: url,
        headers: {authorization: context.state.access_token}
    })
}

export async function logout(context) {
    let response = await app.$axiosCall('DELETE', '/security/logout',null, null, true);
    context.commit('clear');
    await app.$router.push('/');
    return response.status
}
