export function set(state, data) {
    state.logged_user = data;
}

export function clear(state) {
    state.logged_user = null;
}

export function updateSession(state, data) {
    state.logged_user.profileImg = data.profileImg;
    state.logged_user.name = data.name;
}

export function setProfileImg(state, img) {
    state.logged_user.profileImg = img;
}
