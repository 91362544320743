import Vue from 'vue';
import Toasted from 'vue-toasted';

Vue.use(Toasted, {
    iconPack: "material",
    duration: 5000,
    className: 'toast',
    position: "bottom-center",
    singleton: true
});
