import app from '../../main'

export async function create(context, params) {
    let response = await app.$axiosCall('POST', `/${params.type}/create`, params,null, true);
    return await app.$router.push(`/edit/${params.type}/${response.data._id}`);
}

export async function subCreate(context, params) {
    return await app.$axiosCall('POST', `/${params.object}/create`, params,null, true);
}

export async function update(context, params) {
    return await app.$axiosCall('PUT', `/${params.type}/update/${params._id}`, params,null, true);
}

export async function list(context, {type, query, offset, limit}) {
    let url = `/${type}/list?query=${query || ''}&offset=${offset || ''}&limit=${limit || ''}`;
    let response = await app.$axiosCall('GET', url, null,null, true);
    return response.data;
}

export async function remove(context, params) {
    let response = await app.$axiosCall('DELETE', `/${params.type}/delete/${params.id}`, null,null, true);
    return response.status;
}

export async function subList(context, {type, by, id}) {
    let url = type === 'service'? `/${type}/list/${id}` : `/${type}/list/${by}/${id}`;
    let response = await app.$axiosCall('GET', url, null,null, true);
    return response.data;
}

export async function get(context, {type, id}) {
    let response = await app.$axiosCall('GET', `/${type}/detail/${id}`, null,null, true);
    return response.data;
}

export async function toggleMenu(context, value) {
    context.commit('setMenu', value)
}
