import Vue from 'vue'
import VueProgressBar from 'vue-progressbar'

const options = {
    color: '#FFFFFF',
    failedColor: '#c62828',
    thickness: '3px',
    transition: {
        speed: '0.5s',
        opacity: '0.2s',
        termination: 300
    },
    autoRevert: false,
    location: 'top',
    inverse: false
};

Vue.use(VueProgressBar, options);
